import React from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import {PetLaundryAbout} from "../../../components/self-laundry/pet-laundry/petLaundryAbout"
import {PetLaundryHeader} from "../../../components/self-laundry/pet-laundry/petLaundryHeader";
import {PetLaundryUseCase} from "../../../components/self-laundry/pet-laundry/petLaundryUseCase";
import {PetLaundryNotes} from "../../../components/self-laundry/pet-laundry/petLaundryNotes";

const PetLaundry = ({ }) => {
    return (
        <Layout>
            <PetLaundryHeader />
            <PetLaundryAbout />
            <PetLaundryUseCase />
            <PetLaundryNotes />
        </Layout>
    )
}
export const Head = () => (
    <Seo
        title="Pet Laundry"
        description="自宅の洗濯機では洗いにくいペット用品も、バルコなら専用マシンでペットの毛を気にせず洗えます。ペット用品専用の無添加天然洗剤を使用。ダニケアコースなら防虫加工剤「ダニークリーン」で大切なペットをダニや虫から守ります。"
    />
)
export default PetLaundry
