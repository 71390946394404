import React from "react"
import * as FadeIn from "components/fadein"

export const Fv = ({ spFv, pcFv, fvTitle, color }) => {
  return (
    <div className="fv">
      <div className="fv__contents">
        <img
          className="pc"
          src={pcFv}
          height="auto"
          loading="eager"
          alt="firstViewPc"
        />
        <img
          className="sp"
          src={spFv}
          height="auto"
          loading="eager"
          alt="firstViewSp"
        />
      </div>
      <h1 className={`fv__pageTitle e fv__pageTitle--${color}`}>
        <FadeIn.Up>
          <span>{fvTitle}</span>
        </FadeIn.Up>
      </h1>
    </div>
  )
}
