import React from "react";
import {Fv} from "../../fv";

export const PetLaundryHeader = ({ }) => {
    return (
        <div>
            <Fv
                pcFv="https://balukoweb.blob.core.windows.net/images/pet-laundry/pet_mv.jpg"
                spFv="https://balukoweb.blob.core.windows.net/images/pet-laundry/sp_pet_mv.jpg"
                fvTitle="Pet Laundry"
                color="white"
            />
        </div>
    )
}
