import React from "react"

export const PetLaundryNotes = ({ }) => {
    return (
        <section className="petLaundryNotes">
            <div className="petLaundryNotes__contents">
                <h3 className="petLaundryNotes__contentsTitle">Notes</h3>
                <p className="petLaundryNotes__contentsSubTitle">
                    ご注意事項
                </p>
                <ul className="petLaundryNotes__notifications">
                    <li>ペットは店内には入れません。店舗外のリードフックをご利用ください。</li>
                    <li>ランドリーカート、畳台はペット専用のものをご利用ください。</li>
                    <li>ペット用品専用機器以外では、ペット用品の洗濯乾燥はできません。</li>
                    <li>ペット用品以外のものを洗わないでください。</li>
                </ul>
            </div>


        </section>
    )
}
