import React from "react"

export const PetLaundryAbout = ({ }) => {
    return (
        <section className="petLaundryAbout">
            <div className="petLaundryAbout__titleContents">
                <h2 className="petLaundryAbout__title">ペットランドリー</h2>
                <p className="petLaundryAbout__detailText">
                    ペット用品専用のランドリーです。<br />
                    ランドリー内にペット用品専用マシンがあり、自宅の洗濯機では洗いにくいペット用品を専用の洗濯機で洗えます。
                </p>
            </div>

            <div className="petLaundryAbout__features">
                <h3 className="petLaundryAbout__featuresTitle e">Features</h3>
                <p className="petLaundryAbout__featuresSubtitle fw-b">
                    特徴
                </p>

                <div className="petLaundryAbout__featuresList">
                    <div className="petLaundryAbout__featuresItem">
                        <h4 className="petLaundryAbout__featuresItemTitle">ペットの毛を気にせず洗えます</h4>
                        <hr className="petLaundryAbout__featuresItemTitleUnderline" />
                        <p className="petLaundryAbout__featuresItemDetail">ペット専用洗濯機は、従来の洗濯機ドラムと比べて<br />ペットの毛をよりスムーズに排水します。</p>
                    </div>
                    <div className="petLaundryAbout__featuresItem">
                        <h4 className="petLaundryAbout__featuresItemTitle">バルコならではの清潔な空間</h4>
                        <hr className="petLaundryAbout__featuresItemTitleUnderline" />
                        <p className="petLaundryAbout__featuresItemDetail">店内のすみずみまで、スタッフが毎日<br className="petLaundryAbout__SP" />清掃しています。<br />
                            除菌スプレー・除菌ペーパーも完備。<br />
                            テーブルやカートの除菌にご自由にお使いください。</p>
                    </div>
                    <div className="petLaundryAbout__featuresItem">
                        <h4 className="petLaundryAbout__featuresItemTitle">安心の天然成分でペットをダニから守ります</h4>
                        <hr className="petLaundryAbout__featuresItemTitleUnderline" />
                        <p className="petLaundryAbout__featuresItemDetail">ペット用品専用の無添加天然洗剤を使用。<br />
                            ダニケアコースを選択していただくと、<br />
                            防虫加工剤「DANNEY CLEAN（ダニークリーン）」が自動投入されます。</p>
                    </div>
                </div>
                <div className="petLaundryAbout__danneyBanner">
                    <h4 className="petLaundryAbout__danneyBannerTitle">DANNEY CLEAN<span className="petLaundryAbout__danneyBannerSubTitle">（ダニークリーン）</span></h4>
                    <p className="petLaundryAbout__danneyBannerDetail">ペットが舐めても安全なダニよけ成分を配合。<br />
                        抗菌効果と防虫効果を兼ね備えた加工剤で、<br />大切なペットをダニや虫から守ります。</p>
                    <p className="petLaundryAbout__danneyBannerDescription">※すべての菌に対して効果があるわけではありません。</p>
                </div>
            </div>
        </section>
    )
}
