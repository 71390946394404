import React from "react"

export const PetLaundryUseCase = ({ }) => {
    return (
        <section className="petLaundryUseCase">
            <div className="petLaundryUseCase_titleContents">
                <h2 className="petLaundryUseCase__title">Instructions for use</h2>
                <p className="petLaundryUseCase__detailText">
                    洗えるものと量の目安
                </p>
            </div>

            <div className="petLaundryUseCase__showCaseWrap">
                <div className="petLaundryUseCase__showCase">
                    <img className="petLaundryUseCase__showCaseItem" src="https://balukoweb.blob.core.windows.net/images/pet-laundry/pet_icon01.png" alt="ペット服"/>
                    <img className="petLaundryUseCase__showCaseItem" src="https://balukoweb.blob.core.windows.net/images/pet-laundry/pet_icon02.png" alt="ペット用ベッド"/>
                    <img className="petLaundryUseCase__showCaseItem" src="https://balukoweb.blob.core.windows.net/images/pet-laundry/pet_icon03.png" alt="ペット用タオル"/>
                    <img className="petLaundryUseCase__showCaseItem" src="https://balukoweb.blob.core.windows.net/images/pet-laundry/pet_icon04.png" alt="ペット用マット"/>
                </div>

                <div className="petLaundryUseCase_showCaseUnderBox">
                    <h3 className="petLaundryUseCase__showCaseUnderText">1回分の容量目安：ドラムの約8割</h3>
                </div>
                <p className="petLaundryUseCase__showCaseDescription">※素材により破損・縮み・色落ち・移染・型崩れなどが起こる可能性があります。ご利用の際はご自身の判断でご利用ください。</p>
            </div>
            <div className="petLaundryUseCase__footerImageWrap">
                <img className="petLaundryUseCase__footerImagePC"
                     src="https://balukoweb.blob.core.windows.net/images/pet-laundry/pet_dogs.jpg"
                     alt="2 dogs"/>
                <img className="petLaundryUseCase__footerImageSP"
                     src="https://balukoweb.blob.core.windows.net/images/pet-laundry/sp_pet_dogs.jpg"
                     alt="2 dogs"/>
            </div>
        </section>
    )
}
